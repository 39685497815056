import React from "react"
import {StoryGroup} from "components";

const LinkMap = {
	"Architectural Patterns": "2e945dc761b3",
	"Mimari Örüntüler": "2e945dc761b3",
	"Patterns-Structure": "2e945dc761b3",
	"Patterns-Behaviour": "2e945dc761b3",
	"Patterns-Communication": "2e945dc761b3",


	"Microservices": "73bdfddad197",
	"FE Teams": "73bdfddad197",
	"Middleware Teams": "73bdfddad197",
	"DB Teams": "73bdfddad197",
	"System Teams": "73bdfddad197",
	"Fullstack & DevOps": "73bdfddad197",
	"Management": "73bdfddad197",
	"Service Registry": "73bdfddad197",
	"Authorization": "73bdfddad197",
	"Authentication": "73bdfddad197",
	"Transaction Logging": "73bdfddad197",
	"Logging": "73bdfddad197",
	"Usage Tracking": "73bdfddad197",
	"Persistance": "73bdfddad197",
	"Business Ref": "73bdfddad197",
	"Location": "73bdfddad197",
	"Config Mngt": "73bdfddad197",
	"Monitoring": "73bdfddad197",
	"Testing": "73bdfddad197",
	"Key Service": "73bdfddad197",
	"Policy Mngt": "73bdfddad197",
	"Cache": "73bdfddad197",
	"CI/CD": "73bdfddad197",

	"Microfrontends": "e178b73cd1c3",
	"Common Styling": "e178b73cd1c3",
	"Shared Component Library": "e178b73cd1c3",
	"Cross Application Communication": "e178b73cd1c3",
	"Backend Communication BFF": "e178b73cd1c3",
	"Testing2": "e178b73cd1c3",

	"SOA": "75092cd90d61",
	"Monolithic": "75092cd90d61",
	"ESB": "75092cd90d61",
	"ESB-Adapters/Transporters": "75092cd90d61",
	"ESB-Service Hosting": "75092cd90d61",
	"ESB-Security": "75092cd90d61",
	"ESB-Mediation": "75092cd90d61",
	"ESB-Ops&Mngt": "75092cd90d61",

	"Serverless Architecture": "2e5804ececd9",
	"IaaS": "2e5804ececd9",
	"PaaS": "2e5804ececd9",
	"SaaS": "2e5804ececd9",
	"MBaaS": "2e5804ececd9",
	"FaaS": "2e5804ececd9",
	"Compute Layer": "2e5804ececd9",
	"Data Layer": "2e5804ececd9",
	"Message & Streaming Layer": "2e5804ececd9",
	"Edge Layer": "2e5804ececd9",
	"Monitor Layer": "2e5804ececd9",

	"Layered Architecture": "f517b03b050e",
	"Presentation Layer": "f517b03b050e",
	"Business Layer": "f517b03b050e",
	"Data Layer2": "f517b03b050e",

	"Flux Architecture": "a9b2198e3cfa",
	"Dispatcher": "a9b2198e3cfa",
	"Store": "a9b2198e3cfa",
	"View": "a9b2198e3cfa",

	"Redux Thunk": "55329a5f04cf",
	"Redux Saga": "55329a5f04cf",

	"MVC-Model": "80e395179ab0",
	"MVC-View": "80e395179ab0",
	"MVC-Controller": "80e395179ab0",


	"Broker Architecture": "9677be6dfa5a",
	"Proxy": "9677be6dfa5a",

	"Event-Driven": "7d0a7fb57db8",
	"Event-Mediator Topology": "7d0a7fb57db8",
	"Event-Broker Topology": "7d0a7fb57db8",
	
	"event queue": "7d0a7fb57db8",
	"event mediator": "7d0a7fb57db8",
	"event channel": "7d0a7fb57db8",
	"event processor": "7d0a7fb57db8",

	"Blackboard System": "e3d6ad7e5f94",
	"Data Centered": "e3d6ad7e5f94",

	"Pipes": "fee576ef6fb0",
	"Filters": "fee576ef6fb0",

	"Integration Patterns": "2c67e9d7c1c4",
	"Dosya Transferi": "2c67e9d7c1c4",
	"Paylaşılmış Ortak Veritabanı": "2c67e9d7c1c4",
	"Uzak Procedure Çağırma (RPC)": "2c67e9d7c1c4",
	"Mesajlaşma": "2c67e9d7c1c4",

	"Message(Mesaj)": "2c67e9d7c1c4",
	"Channel (Kanal)": "2c67e9d7c1c4",
	"Sender/Producer": "2c67e9d7c1c4",
	"Receiver/Consumer": "2c67e9d7c1c4",

	"Uzak İletişim": "2c67e9d7c1c4",
	"Platform/Dil": "2c67e9d7c1c4",
	"Asenkron": "2c67e9d7c1c4",
	"Değişken Zamanlama": "2c67e9d7c1c4",
	"Throttling": "2c67e9d7c1c4",
	"Güvenilir İletişim": "2c67e9d7c1c4",
	"Offline Çalışma": "2c67e9d7c1c4",
	"Mediation": "2c67e9d7c1c4",
	"Thread Management": "2c67e9d7c1c4",

}


const stories = [
	{
		title: "Mimari Örüntülere Giriş",
		postId: "2e945dc761b3",
	},
	{
		title: "Microservices Mimarisi",
		postId: "73bdfddad197",
	},
	{
		title: "Microfrontends Mimarisi",
		postId: "e178b73cd1c3",
	},
	{
		title: "SOA Mimarisi",
		postId: "75092cd90d61",
	},
	{
		title: "Serverless Architecture (FaaS)",
		postId: "2e5804ececd9",
	},
	{
		title: "Katmanlı Uygulama Mimarisi",
		postId: "f517b03b050e",
	},
	{
		title: "Flux Patten (Redux, vb...)",
		postId: "a9b2198e3cfa",
	},
	{
		title: "Saga Pattern",
		postId: "55329a5f04cf",
	},
	{
		title: "MVC (Model View Controller)",
		postId: "80e395179ab0",
	},
	{
		title: "Yazılım Mimarisinde Brokerlik",
		postId: "9677be6dfa5a",
	},
	{
		title: "Event-Driven Architecture",
		postId: "7d0a7fb57db8",
	},
	{
		title: "Blackboard System",
		postId: "e3d6ad7e5f94",
	},
	{
		title: "Pipe and filter architecture",
		postId: "fee576ef6fb0",
	},
	{
		title: "Entegrasyon Örüntüler",
		postId: "2c67e9d7c1c4",
	}


]


export const context = {
	stories: stories,
	linkMap: LinkMap,
	title: "Mimari Örüntüler",
	path: 'mimari-oruntuler',
}


const ArchPatternsPage = () => {

	return (
		<StoryGroup
			title={context.title}
			stories={context.stories}
			linkMap={context.linkMap}
		/>
	)

}

export default ArchPatternsPage



